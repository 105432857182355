export default [
  {
    category: require("../../../assets/drugImgs/img-title-category01.png"),
    type: "家庭常备",
    data: [
      {
        img: require("../../../assets/drugImgs/j-aj.png"),
        label: "东阿阿胶 阿胶补血颗粒(无糖) 4g*30袋",
        price: "¥138.00",
        sales: 221,
        brand: "东阿阿胶",
        manufacturer: "山东东阿阿胶股份有限公司",
        approvalNumber: "Z37021196",
        adverseReactions: "尚不明确",
        treatment: "益气补血。用于久病体弱，气虚血亏。",
        ingredient: "",
        enterprise: "卫生部药品标准中药成方制剂第十三册",
        productName: "阿胶补血颗粒",
        commoName: "阿胶补血颗粒",
        dosage: "开水冲服，一次一袋，一日2次。",
        specification: ["4g*30袋"],
        precautions:
          "1.本品为气血双补之药，咳嗽痰多，脘腹胀痛，纳食不消，腹胀便溏者不宜服用。2.服本药时不宜同时服用藜芦或其制剂。3.不宜和感冒类药同时服用。4.高血压，糖尿病患者或正在接受其他药物治疗者应在医师指导下服用。5.本品宜饭前服用或进食同时服。6.按照用法用量服用，小儿应在医师指导下服用。7.服药期间出现食欲不振，恶心呕吐，腹胀便溏者应去医院就诊。8.对本品过敏者禁用，过敏体质者慎用。9.本品性状发生改变时禁止使用。10.儿童必须在成人监护下使用。11.请将本品放在儿童不能接触的地方。12.如正在使用其他药品，使用本品前请咨询医师或药师。",
      },
      {
        img: require("../../../assets/drugImgs/j-ypyl.png"),
        label: "雅培益力佳SR营养配方粉糖尿病人辅助降糖",
        price: "¥156.00",
        sales: 159,
        brand: "雅培益力佳",
        manufacturer: "美国雅培制药有限公司",
        approvalNumber: "国食健字J20050003",
        adverseReactions: "尚不明确",
        treatment:
          "本产品是以麦芽糖精，酪蛋白钙肽，高油酸葵花籽油，大豆油，果糖，麦芽糖醇，硫酸镁等为主要原料制成的保健食品，经功能试验证明，具有辅助降血糖的保健功能。",
        ingredient:
          "麦芽糊精，酪蛋白钙肽，高油酸葵花籽油，大豆油，果糖，麦芽糖醇，硫酸镁，磷酸钠，氯化钾，碳酸钙，氯化钠，硫酸亚铁，硫酸锌，硫酸锰，硫酸铜，三氯化铬，硒酸钠，大豆多糖等。",
        enterprise: "美国雅培制药有限公司",
        productName: "雅培益力佳SR营养配方粉糖尿病人辅助降糖",
        commoName: "营养配方粉",
        dosage:
          "每次52克，每日1次， 用52克本品（6量匙）缓慢添加于200毫升温开水中，混合搅拌直到完全溶解后食用。",
        specification: ["400g"],
        precautions:
          "1.不适宜人群：少年儿童、1-4岁的婴幼儿、半乳糖血症患者。2.少年儿童、1-4岁的婴幼儿、半乳糖血症患者。",
      },
      {
        img: require("../../../assets/drugImgs/j-xmqw.png"),
        label: "小麦纤维素颗粒(非比麸) ",
        price: "¥98.00",
        sales: 279,
        brand: "Recip AB(瑞典)",
        manufacturer: "Recip AB(瑞典)",
        approvalNumber: "H20170267",
        adverseReactions: "尚不明确",
        treatment:
          "本品用于便秘；作为肠易激综合征、憩室病、肛裂和痔疮等伴发便秘的辅助治疗；也可用于手术后软化大便。",
        ingredient: "本品主要成分为小麦纤维素。",
        enterprise: "Recip AB(瑞典)",
        productName: "小麦纤维素颗粒(非比麸)",
        commoName: "小麦纤维素颗粒",
        dosage:
          "成人：一次3.5g(一次一包)，一天2－3次；至少一周，之后逐渐减量至每日2次或1次；每日清晨都应服药。 6个月以上儿童：一次1.75 g(一次半包)，一天1－2次；至少一周，之后逐渐减量至每日1次；每日清晨都应服药。 本品可加入食物或饮料中服用，如汤、粥、牛奶、果汁等，每次用200ml左右的液体一起服用可达最佳效果。",
        specification: ["3.5g*10包"],
        precautions:
          " 1.建议患者服用本品期间建议患者喝足量的水，可达到最佳效果。 2.本品基本不含麸质(每100g产品含少于0.02g的麸质),对小麦过敏的病人可能对本品产生过敏反应。请仔细阅读说明书并遵医嘱使用。",
      },
      {
        img: require("../../../assets/drugImgs/j-gml.png"),
        label: "感冒灵颗粒(999) ",
        price: "¥14.00",
        sales: 565,
        brand: "999",
        manufacturer: "华润三九医药股份有限公司",
        approvalNumber: "Z44021940",
        adverseReactions:
          "偶见皮疹、荨麻疹、药热及粒细胞减少；可见困倦、嗜睡、口渴、虚弱感；长期大量用药会导致肝肾功能异常。",
        treatment: "解热镇痛。用于感冒引起的头痛，发热，鼻塞，流涕，咽喉痛。",
        ingredient:
          "三叉苦、金盏银盘、野菊花、岗梅、咖啡因、对乙酰氨基酚、马来酸氯苯那敏、薄荷油。辅料为蔗糖粉。",
        enterprise: "华润三九医药股份有限公司",
        productName: "感冒灵颗粒",
        commoName: "感冒灵颗粒",
        dosage: "成人：一次3.5g(一次一包)，一天2－3次；至少一周，之",
        specification: ["10g*9袋"],
        precautions:
          "1.忌烟、酒及辛辣、生冷、油腻食物。2.不宜在服药期间同时服用滋补性中成药。3.本品含对乙酰氨基酚、马来酸氯苯那敏、咖啡因。服用本品期间不得饮酒或含有酒精的饮料；不能同时服用与本品成份相似的其他抗感冒药；肝、肾功能不全者慎用；膀胱颈梗阻、甲状腺功能亢进、青光眼、高血压和前列腺肥大者慎用；孕妇及哺乳期妇女慎用；服药期间不得驾驶机、车、船、从事高空作业、机械作业及操作精密仪器。4.脾胃虚寒，症见腹痛、喜暖、泄泻者慎用。5.糖尿病患者及有心脏病等慢性病严重者应在医师指导下服用。6.儿童、年老体弱者应在医师指导下服用。7.服药3天后症状无改善，或症状加重，或出现新的严重症状如胸闷、心悸等应立即停药，并去医院就诊。8.对本品过敏者禁用，过敏体质者慎用。9.本品性状发生改变时禁止使用。10.儿童必须在成人监护下使用。11.请将本品放在儿童不能接触的地方。12.如正在使用其他药品，使用本品前请咨询医师或药师。",
      },
    ],
  },
  {
    category: require("../../../assets/drugImgs/img-title-category02.png"),
    type: "调理滋补",
    data: [
      {
        img: require("../../../assets/drugImgs/t-trt.png"),
        label: "同仁堂牌破壁灵芝孢子粉胶囊",
        price: "¥980.00",
        sales: 102,
        brand: "同仁堂",
        manufacturer: "北京同仁堂科技发展股份有限公司制药",
        approvalNumber: "Z44021940",
        adverseReactions: "尚不明确",
        treatment: "本品经动物实验评价，具有增强免疫力的保健功能",
        ingredient: "每100g含灵芝三萜2.0g。",
        enterprise: "北京同仁堂科技发展股份有限公司制药",
        productName: "同仁堂牌破壁灵芝孢子粉胶囊",
        commoName: "同仁堂牌破壁灵芝孢子粉胶囊",
        dosage: "每日2次，每次2粒，口服",
        specification: ["0.35g*90粒"],
        precautions: "本品不能代替药物；适宜人群外的人群不推荐食用本产品",
      },
      {
        img: require("../../../assets/drugImgs/j-ypyl.38429cb3.png"),
        label: "维生素E软胶囊（100毫克*15粒*2板）",
        price: "¥145.00",
        sales: 231,
        brand: "中智",
        manufacturer: "中山市中智中药饮品有限公司",
        approvalNumber: "Z44021940",
        adverseReactions: "尚不明确",
        treatment: "本品经动物实验评价，具有增强免疫力的保健功能",
        ingredient: "天麻",
        enterprise: "中山市中智中药饮片有限公司",
        productName: "天麻破壁草本(草晶华)",
        commoName: "天麻破壁草本",
        dosage:
          " 冲泡服用。一次1～2袋，一日2～3次。可反复冲至无味时连同药渣一起服用。或遵医嘱。",
        specification: ["1g*20袋"],
        precautions: "请仔细阅读说明书并遵医嘱使用。",
      },
      {
        img: require("../../../assets/drugImgs/t-tmpb.png"),
        label: "天麻破壁草本(草晶华)",
        price: "¥55.00",
        sales: 334,
        brand: "康恩贝",
        manufacturer: "山东圣海保健品有限公司",
        approvalNumber: "G201937000239",
        adverseReactions: "尚不明确",
        treatment: "补充维生素E。",
        ingredient: "天然维生素E、大豆油、明胶、甘油、水。",
        enterprise: "山东圣海保健品有限公司",
        productName: "维生素E软胶囊(康恩贝 贝兴牌)",
        commoName: "维生素E软胶囊",
        dosage: " 每日1次，每次1粒。",
        specification: ["0.45g*120s"],
        precautions:
          "本品不能代替药物；不宜超过推荐量或与同类营养补充剂同时食用。",
      },
      {
        img: require("../../../assets/drugImgs/t-tcbj.png"),
        label: "汤臣倍健褪黑素片改善睡眠助退黑素改善失眠非安眠药保健品 ",
        price: "¥81.00",
        sales: 236,
        brand: "汤臣倍健",
        manufacturer: "广东汤臣倍健生物科技股份有限公司",
        approvalNumber: "G20150889",
        adverseReactions: "尚不明确",
        treatment: "改善睡眠。",
        ingredient: "褪黑素，维生素B6。",
        enterprise: "广东汤臣倍健生物科技股份有限公司",
        productName: "汤臣倍健褪黑素片",
        commoName: "褪黑素片",
        dosage: " 每日1次，每次1片，睡前30分钟食用。",
        specification: ["400mg*60s"],
        precautions:
          "本品不能代替药物；从事驾驶、机械作业或危险操作者，不要在操作前或操作中食用；自身免疫症（类风湿等）及甲亢患者慎用。",
      },
    ],
  },
  {
    category: require("../../../assets/drugImgs/img-title-category03.png"),
    type: "男性专区",
    data: [
      {
        img: require("../../../assets/drugImgs/n-sbp.png"),
        label: "【温阳补肾强身】 汇仁 肾宝片 0.7g*126s",
        price: "¥288.00",
        sales: 126,
        brand: "汇仁",
        manufacturer: "江西汇仁药业有限公司",
        approvalNumber: "Z20080627",
        adverseReactions: "尚不明确",
        treatment:
          "调和阴阳，温阳补肾，扶正固本。用于腰腿酸痛，精神不振，夜尿频多，畏寒怕冷；妇女白带清稀。",
        ingredient:
          "淫羊藿、葫芦巴、金樱子、熟地黄、补骨脂、蛇床子、制何首乌、肉苁蓉、枸杞子、菟丝子、五味子、履盆子、黄氏、红参、白术、山药、茯苓、当归、川穹、小茴香、车前子、炙甘草，辅料为羟丙基纤维素，硬脂酸镁，二氧化硅。",
        enterprise: "江西汇仁药业有限公司",
        productName: "肾宝片",
        commoName: "肾宝片",
        dosage: "口服，一次3片，一日三次。",
        specification: ["45片*2盒"],
        precautions:
          "1.忌油腻食物。2.凡脾胃虚弱，呕吐泄泻，腹胀便溏、咳嗽痰多者慎用。3.感冒病人不宜服用。4.高血压患者应在医师指导下服用。5.服用本品同时不宜服用藜芦、五灵脂、皂荚或其制剂；不宜喝茶和吃萝卜，以免影响药效。6.本品宜饭前服用。7.服药二周或服药期间症状无改善，或症状加重，或出现新的严重症状，应立即停药并去医药就诊。8.对本品过敏者禁用，过敏体质者慎用。9.本品性状发生改变时禁止使用。10.请将本品放在儿童不能接触的地方。11.如正在使用其他药品，使用本品前请咨询医师或药师。",
      },
      {
        img: require("../../../assets/drugImgs/n-hrsbp.png"),
        label: "万艾可 枸橼酸西地那非片 25mg*7片",
        price: "¥248.00",
        sales: 36,
        brand: "万艾可",
        manufacturer: "辉瑞制药有限公司",
        approvalNumber: "国药准字 H20020526",
        adverseReactions:
          "上市后报告的其它与服用西地那非有时间联系、但未在上市前研究中出现的不良事件有： 　　神经系统：癫痫发作和焦虑。 　　泌尿生殖系统：勃起时间延长、异常勃起（见“警告”）和血尿。 　　特殊感觉：复视、短暂视觉丧失或视力下降、红眼或眼部充血、眼部烧灼感、眼部肿胀和压迫感、眼内压增高、视网膜血管病变或出血、玻璃体剥离、黄斑周围水肿及鼻衄等。 　　在PDE5 抑制剂包括万艾可的上市后应用中，均有与用药时间相关的非动脉性前部缺血性视神经病（NAION）的罕见报告。NAION 是一种可引起视力下降包括永久性丧失的疾病，在大多数但非所有情况下，这些患者均具有潜在的患NAION 的解剖或血管方面的基础或危险因素，包括但不限于：低杯盘比，年龄超过50 岁，糖尿病，高血压，冠状动脉疾病，高脂血症和吸烟。 　　不能确定这些事件与应用PDE5 抑制剂直接相关；或与患者的基础血管危险因素或解剖缺陷有关；或是二者的共同作用；抑或是其他因素所致。（见“【注意事项】/患者须知”部分）。 　　血液淋巴系统：血管阻塞危象与接受万艾可治疗的ED 男性的临床相关性尚不清楚。",
        treatment: "西地那非适用于治疗勃起功能障碍。",
        ingredient:
          "本品主要成分及其化学名称为：1－[4－乙氧基－3－（6，7－二氢－1－甲基－7－氧代－3－丙基－1氢－吡唑并[4，3d]嘧啶－5－基）苯 磺酰]－4－甲基哌嗪枸橼酸盐",
        enterprise: "辉瑞制药有限公司",
        productName: "万艾可 枸橼酸西地那非片",
        commoName: "万艾可 枸橼酸西地那非片",
        dosage: "推荐剂量50mg。",
        specification: ["25mg*7片"],
        precautions:
          " 一般事项诊断勃起功能障碍的同时应明确其潜在的病因，进行全面的医学检查后确定适当的治疗方案。 　　在给病人应用西地那非之前，须注意以下一些重要问题：PDE5（5型磷酸二酯酶）抑制剂与α受体阻滞剂合用时需谨慎。PDE5抑制剂（包括本品）与α受体阻滞剂同为血管扩张剂，都具有降低血压的作用。当合用血管扩张剂时，可以预期对血压的作用可能累加。在部分患者中，这两类药物合用可显著降低血压，导致低血压症状（如头晕、头昏、昏厥）（见【药物相互作用】）。",
      },
      {
        img: require("../../../assets/drugImgs/n-lwdhw.png"),
        label: "同仁堂 六味地黄丸 360丸",
        price: "¥17.9",
        sales: 16,
        brand: "同仁堂",
        manufacturer: "北京同仁堂科技发展股份有限公司制药厂",
        approvalNumber: "国药准字Z11021283",
        adverseReactions: "尚不明确",
        treatment:
          "滋阴补肾。用于肾阴亏损，头晕耳鸣，腰膝酸软，骨蒸潮热，盗汗遗精。",
        ingredient: "熟地黄、酒萸肉、牡丹皮、山药、茯苓、泽泻。辅料为蜂蜜。",
        enterprise: "北京同仁堂科技发展股份有限公司制药厂",
        productName: "六味地黄丸",
        commoName: "六味地黄丸",
        dosage: "口服。水蜜丸一次30粒（6克），一日2次。",
        specification: ["360丸"],
        precautions:
          "1.忌不易消化食物。2.感冒发热病人不宜服用。3.有高血压、心脏病、肝病、糖尿病、肾病等慢性病严重者应在医师指导下服用。4.儿童、孕妇、哺乳期妇女应在医师指导下服用。5.服药4周症状无缓解，应去医院就诊。6.对本品过敏者禁用，过敏体质者慎用。7.本品性状发生改变时禁止使用。8.儿童必须在成人监护下使用。9.请将本品放在儿童不能接触的地方。10.如正在使用其他药品，使用本品前请咨询医师或药师。",
      },
      {
        img: require("../../../assets/drugImgs/n-trtlwdhw.png"),
        label: "同仁堂 锁阳固精丸 9g*10丸",
        price: "¥29.8",
        sales: 12,
        brand: "同仁堂",
        manufacturer: "北京同仁堂科技发展股份有限公司制药厂",
        approvalNumber: "国药准字Z11020033",
        adverseReactions: "尚不明确",
        treatment: "温肾固精。用于肾阳不足所致的腰膝酸软、头晕耳鸣、遗精早泄。",
        ingredient:
          "锁阳、肉纵蓉(蒸)、巴嫩天(制)、补骨脂(盐炒)、菟丝子、杜仲(炭)、八角茴香、韭菜子、芡实（炒）、莲子、莲须、煅牡蛎、龙骨（煅）、鹿角霜、熟地黄、山茱萸（制）、牡丹皮、山药、茯苓、泽泻、知母、黄柏、牛膝、大青盐。辅料为赋形剂蜂蜜。",
        enterprise: "北京同仁堂科技发展股份有限公司制药厂",
        productName: "锁阳固精丸",
        commoName: "锁阳固精丸",
        dosage: "口服。一次1丸，一日2次。",
        specification: ["9g*10s"],
        precautions:
          "1.忌不易消化食物。2.治疗期间，宜节制房事。3.感冒发热病人不宜服用。4.有高血压、心脏病、肝病、糖尿病、肾病等慢性病严重者应在医师指导下服用。5.儿童、孕妇、哺乳期妇女应在医师指导下服用。6.服药4周症状无缓解，应去医院就诊。7.对本品过敏者禁用，过敏体质者慎用。8.本品性状发生改变时禁止使用。9.儿童必须在成人监护下使用。10.请将本品放在儿童不能接触的地方。11.如正在使用其他药品，使用本品前请咨询医师或药师。12.服用前应除去蜡皮，塑料球壳，本品可嚼服，也可分份吞服。",
      },
    ],
  },
  {
    category: require("../../../assets/drugImgs/img-title-category04.png"),
    type: "女性专区",
    data: [
      {
        img: require("../../../assets/drugImgs/v-pbbzjl.png"),
        label: "盘龙云海 排毒养颜胶囊 0.4g*70s",
        price: "¥129.00",
        sales: 136,
        brand: "盘龙云海",
        manufacturer: "云南盘龙云海",
        approvalNumber: "Z53020685",
        adverseReactions: "尚不明确",
        treatment:
          "益气活血、通便排毒，用于气虚血瘀，热毒内盛所致便秘、痤疮、颜面色斑。",
        ingredient:
          "大黄、白术、西洋参、芒硝、枳实、青阳参、小红参、肉苁蓉、荷叶。",
        enterprise: "云南盘龙云海",
        productName: "排毒养颜胶囊",
        commoName: "排毒养颜胶囊",
        dosage: "口服。水蜜丸一次30粒（6克），一日2次。",
        specification: ["0.4g*70s"],
        precautions: "详见说明书",
      },
      {
        img: require("../../../assets/drugImgs/v-qxjl.png"),
        label: "摩美得 气血和胶囊 0.4g*216s",
        price: "¥268.00",
        sales: 123,
        brand: "摩美得",
        manufacturer: "陕西摩美得制药有限公司",
        approvalNumber: "B20020272",
        adverseReactions: "尚不明确",
        treatment:
          "疏肝理气，活血止痛。用于妇女月经过少.经期后错，行经不畅，经色黯红有血块.小腹或少腹疼痛，经前乳房胀痛，或伴有黄褐斑等面部色素沉着。",
        ingredient:
          "当归.赤芍.川芎.桃仁.红花.桔梗.牛膝.枳壳.柴胡.香附.乌药.丹参.延胡索.升麻.甘草。",
        enterprise: "陕西摩美得制药有限公司",
        productName: "气血和胶囊",
        commoName: "气血和胶囊",
        dosage: "口服，一次4粒，一日3次",
        specification: ["0.4g*216s"],
        precautions:
          "1.忌食辛辣.生冷食物。2.感冒时不宜服用。患有其他疾病者，应在医师指导下服用。3.月经过多者不宜服用。4.平素月经正常，突然出现月经过少，或经期错后，或阴道不规则出血者应去医院就诊。5.服药2周症状无缓解，应去医院就诊。6.对本品过敏者禁用，过敏体质者慎用。7.药品性状发生改变时禁止服用。8.请将此药品放在儿童不能接触的地方。9.如正在服用其他药品，使用本品前请咨询医师或药师。",
      },
      {
        img: require("../../../assets/drugImgs/v-fpaj.png"),
        label: "福牌 阿胶 250克（红铁盒）",
        price: "¥548.00",
        sales: 125,
        brand: "福牌",
        manufacturer: "山东福胶集团东阿镇阿胶有限公司",
        approvalNumber: "Z37021269 ",
        adverseReactions: "尚不明确",
        treatment:
          "补血滋阴,润燥、止血用于血虚萎黄,眩晕心悸,心烦不眠,肺燥咳嗽。",
        ingredient: "驴皮。辅料；冰糖，黄酒，豆油。",
        enterprise: "云南盘龙云海",
        productName: "福牌福胶阿胶(精装)250g",
        commoName: "阿胶",
        dosage: "烊化兑服，3~9克。",
        specification: ["250克（红铁盒）"],
        precautions:
          "1.忌油腻食物。2.凡脾胃虚弱，呕吐泄泻，腹胀便溏、咳嗽痰多者慎用。3.感冒病人不宜服用。4.孕妇、高血压、糖尿病患者应在医师指导下服用。5.本品宜饭前服用。6.按照用法用量服用，小儿应在医师指导下服用。7.服药二周或服药期间症状无改善，或症状加重，或出现新的严重症状，应立即停药并去医院就诊。8.对本品过敏者禁用，过敏体质者慎用。9.本品性状发生改变时禁止使用。10.儿童必须在成人监护下使用。11.请将本品放在儿童不能接触的地方。12.如正在使用其他药品，使用本品前请咨询医师或药师。",
      },
      {
        img: require("../../../assets/drugImgs/v-alwfhwssp.png"),
        label: "爱乐维 复合维生素片 100片",
        price: "¥350.00",
        sales: 23,
        brand: "爱乐维",
        manufacturer: "阿根廷Bayer S.A",
        approvalNumber: "J20140155",
        adverseReactions: "详见说明书",
        treatment:
          "用于满足妇女怀孕时及产后对维生素、矿物质和微量元素的额外需求。 预防孕期由于缺铁和缺乏叶酸引起的贫血。",
        ingredient:
          "本品为复方制剂，每片含：12种维生素 7种矿物质和微量元素：维生素A(4,000国际单位) 1.2毫克、 钙 0.125克 、维生素B1 1.6毫克、 镁 0.1克 、维生素B2 1.8毫克、 磷 0.125克、 维生素B6 2.6毫克、 铜 1毫克 、维生素B12 4.0微克、 铁 60毫克 、维生素C 0.1克 、锰 1毫克、维生素D3(500国际单位) 12.5微克 、锌 7.5毫克 、维生素E 15毫克、生物素 0.2毫克、叶酸 0.8毫克、烟酰胺 19毫克、泛酸钙 10毫克。",
        enterprise: "Bayer S.A.",
        productName: "爱乐维",
        commoName: "复合维生素片",
        dosage: "一次1片，一日1次。",
        specification: ["100片"],
        precautions: "详见说明书",
      },
    ],
  },
  {
    category: require("../../../assets/drugImgs/img-title-category05.png"),
    type: "老人专区",
    data: [
      {
        img: require("../../../assets/drugImgs/l-ttjxkf.png"),
        label: "太太 静心口服液 15ml*10支",
        price: "¥48.00",
        sales: 36,
        brand: "太太",
        manufacturer: "深圳太太药业有限公司",
        approvalNumber: "B20020088 ",
        adverseReactions: "尚不明确",
        treatment:
          "滋养肝肾，宁心安神。用于更年期妇女阴虚肝旺所致的烘热汗出，头晕耳鸣，烦躁，腰膝酸软，失眠多梦的辅助治疗。",
        ingredient:
          "炒枣仁，地黄，生牡蛎，白芍，枸杞子，当归，百合，北五味子，莲子心，蜂蜜，甜菊素等",
        enterprise: "深圳太太药业有限公司",
        productName: "静心口服液",
        commoName: "静心口服液",
        dosage:
          "口服，一次15毫升，一日2次，三周为一疗程，连续用药不超过三个疗程。",
        specification: ["15ml*10支"],
        precautions:
          "1.忌食辛辣，少进油腻。2.感冒时不宜服用。妇科出血患者慎服。3.伴有月经紊乱或其他疾病如：高血压、心脏病、糖尿病、肾病等患者，应在医师指导下服用。4.头晕症状较重者，应去医院就诊。5.服药2周症状无缓解，应去医院就诊。6.对该药品过敏者禁用，过敏体质者慎用。7.该药品性状发生改变时禁止使用。8.请将该药品放在儿童不能接触的地方。9.如正在使用其他药品，使用该药品前请咨询医师或药师。",
      },
      {
        img: require("../../../assets/drugImgs/l-xswssd.png"),
        label: "星鲨 维生素D滴剂 400IU*12粒*3板",
        price: "¥47.5",
        sales: 43,
        brand: "星鲨",
        manufacturer: "国药控股星鲨制药（厦门）有限公司",
        approvalNumber: "H35021450 ",
        adverseReactions: "尚不明确",
        treatment: "补充维生素、佝偻病、小儿佝偻病",
        ingredient:
          "本品每粒含维生素D3 400单位。辅料为：植物油、明胶、甘油、纯化水。",
        enterprise: "国药控股星鲨制药（厦门）有限公司",
        productName: "维生素D滴剂",
        commoName: "维生素D滴剂",
        dosage: "口服。成人与儿童一日1—2粒。",
        specification: ["400IU*12粒*3板"],
        precautions:
          "1.下列情况慎用：动脉硬化、心功能不全、高胆固醇血症、高磷血症、对维生素D高度敏感及肾功能不全患者。 2.婴儿应在医师指导下使用。 3.必须按推荐剂量服用，不可超量服用。 4.对本品过敏者禁用，过敏体质者慎用。 5.本品性状发生改变时禁止使用。 6.请将本品放在儿童不能接触的地方。 7.儿童必须在成人监护下使用。 8.如正在使用其他药品，使用本品前请咨询医师或药师。症状—— 维生素缺乏症",
      },
      {
        img: require("../../../assets/drugImgs/l-wdgzjp.png"),
        label: "D-Cal/迪巧 维D钙咀嚼片 120片",
        price: "¥108.00",
        sales: 146,
        brand: "D-Cal/迪巧",
        manufacturer: "安士制药(中山)有限公司",
        approvalNumber: "H20140588",
        adverseReactions: "尚不明确",
        treatment:
          " 用于妊娠、哺乳妇女，更年期妇女、老年人等的钙补充，也用于防治骨质疏松",
        ingredient:
          "本品为复方制剂，每片含碳酸钙0.75克（相当于钙300毫克），维生素D3100国际单位辅料为：山梨糖醇、硬脂酸镁、水果香料、着色剂",
        enterprise: "美国安士制药有限公司",
        productName: "迪巧 维D钙咀嚼片成人型",
        commoName: "维D钙咀嚼片成人型",
        dosage: "口服，咀嚼后咽下儿童，一次1片；成人，一次2片；一日1次",
        specification: ["120片"],
        precautions: "详见说明书",
      },
      {
        img: require("../../../assets/drugImgs/l-jgeqcsgwd.png"),
        label: "金钙尔奇 碳酸钙维D3元素片 100片",
        price: "¥125.00",
        sales: 123,
        brand: "金钙尔奇",
        manufacturer: "惠氏制药有限公司",
        approvalNumber: "国药准字H20110006",
        adverseReactions: "尚不明确",
        treatment:
          "用于成年人，特别是中、老年人和绝经后妇女的钙补充剂；并帮助防治骨质疏松症。",
        ingredient:
          "本品为复方制剂，每片含碳酸钙1.5g（相当于钙6mg）、D32国际单位、氧化铜1.252mg（以铜计1mg）、氧化镁82.89mg（以镁计5mg）、氧化锌9.337mg（以锌计7.5mg）、一水硫酸锰5.541mg（以锰计1.8mg）。辅料为：交联羧甲基纤维素钠，微晶纤维素，硬脂酸镁，交联吡络烷酮，吡络烷酮，十二烷基硫酸钠，灰玫瑰红色色素（包衣材料），轻质矿物油（包衣材料）。",
        enterprise: "惠氏制药有限公司",
        productName: "金钙尔奇D碳酸钙维D3元素片3片",
        commoName: "碳酸钙维D3元素片",
        dosage: "口服，一次1片，一日1次，饭后即服。",
        specification: ["100片"],
        precautions: "详见说明书",
      },
    ],
  },
  {
    category: require("../../../assets/drugImgs/img-title-category06.png"),
    type: "儿童专区",
    data: [
      {
        img: require("../../../assets/drugImgs/er-zjtzjkl.png"),
        label: "仲景 太子金颗粒 1g*6袋",
        price: "¥38.00",
        sales: 97,
        brand: "仲景",
        manufacturer: "河南省宛西制药股份有限公司",
        approvalNumber: "B20110001",
        adverseReactions: "尚不明确",
        treatment:
          "健脾和胃，消积增食。用于小儿乳食戎退卵崾常涣迹涓拐吐嫔藁翁逑荩蟊闶У鞯母ㄖ瘟啤。",
        ingredient:
          "太子参、枳实（炒）、砂仁、鸡内金（醋制）、山楂（炒焦）、鳖甲（醋制）、穿山甲（制）。辅料为蔗糖。",
        enterprise: "河南省宛西制药股份有限公司",
        productName: "太子金颗粒",
        commoName: "太子金颗粒",
        dosage:
          "口服。1~3岁一次1/3~1袋，3~6岁一次1~1.5袋，6~9岁一次1.5~3袋，9~12岁一次3~4.5袋，一日3~4次。或遵医嘱。",
        specification: ["1g*6袋"],
        precautions:
          "1.忌食生冷油腻及不易消化食物.2.婴儿应在医师指导下服用.3.感冒时不宜服用.4.长期厌食，体弱消瘦者，及腹胀重、腹泻次数增多者应去医院就诊.5.服药7天症状无缓解，应去医院就诊.6.对本品过敏者禁用，过敏体质者慎用.7.本品性状发生改变时禁止使用.8.儿童必须在成人监护下使用.9.请将本品放在儿童不能接触的地方.10.如正在使用其他药品，使用本品前请咨询医师或药师.",
      },
      {
        img: require("../../../assets/drugImgs/er-mmakcgj.png"),
        label: "妈咪爱 枯草杆菌二联活菌颗粒 1g*30袋",
        price: "¥56.00",
        sales: 22,
        brand: "妈咪爱",
        manufacturer: "北京韩美药品有限公司",
        approvalNumber: "S20020037",
        adverseReactions: "尚不明确",
        treatment: "适用于因肠道菌群失调引起的腹泻、便秘、胀气、消化不良等。",
        ingredient:
          "本品为复方制剂，每袋(1克)含活菌冻干粉：37.5毫克、维生素C：10毫克、维生素B1：0.5毫克、维生素B2：0.5毫克、维生素B6：0.5毫克、维生素B12：1.0微克、烟酰胺：2.0毫克、乳酸钙：20毫克(相当于钙2.6毫克)、氧化锌：1.25毫克(相当于锌1.0毫克)。辅料为：乳糖、D-甘露醇、聚维酮K30、轻质无水硅酸、甜菊素。",
        enterprise: "北京韩美药品有限公司",
        productName: "妈咪爱",
        commoName: "枯草杆菌二联活菌颗粒",
        dosage:
          "口服。1~3岁一次1/3~1袋，3~6岁一次1~1.5袋，6~9岁一次1.5~3袋，9~12岁一次3~4.5袋，一日3~4次。或遵医嘱。",
        specification: ["1g*30袋"],
        precautions:
          " 1、直接服用时应注意避免呛咳，不满3岁的婴幼儿不宜直接服用。2、本品为活菌制剂，切勿将本品置于高温处，溶解时水温不宜超过40℃。3、对本品过敏者禁用，过敏体质者慎用。4、本品性状发生改变时禁止使用。5、请将本品放在儿童不能接触的地方。6、儿童必须在成人监护下使用。7、如正在使用其他药品，使用本品前请咨询医师或药师。",
      },
      {
        img: require("../../../assets/drugImgs/er-kqewdlpgp.png"),
        label: "卡奇尔 维D2磷葡钙片 90片",
        price: "¥78.00",
        sales: 46,
        brand: "卡奇尔",
        manufacturer: "华润双鹤药业股份有限公司",
        approvalNumber: "H11022441 ",
        adverseReactions: "尚不明确",
        treatment:
          "本品用于儿童、孕妇、哺乳期妇女、钙磷的补充，也可用于预防和治疗佝偻病",
        ingredient:
          "本品为复方制剂，每片含葡萄糖酸钙.197克，磷酸氢钙.139克，维生素D21单位",
        enterprise: "华润双鹤药业股份有限公司",
        productName: "卡奇尔维D2磷葡钙片6片",
        commoName: "维D2磷葡钙片",
        dosage:
          "口服。1~3岁一次1/3~1袋，3~6岁一次1~1.5袋，6~9岁一次1.5~3袋，9~12岁一次3~4.5袋，一日3~4次。或遵医嘱。",
        specification: ["90片"],
        precautions: "详见说明书",
      },
      {
        img: require("../../../assets/drugImgs/er-dcaldg.png"),
        label: "D-Cal/迪巧 儿童维D钙咀嚼片 60片",
        price: "¥105.00",
        sales: 67,
        brand: "D-Cal/迪巧",
        manufacturer: "安士制药(中山)有限公司",
        approvalNumber: "J20140153",
        adverseReactions: "1.嗳气、便秘 2.过量服用可发生高钙血症,奶-碱综合征",
        treatment: "儿童钙补充",
        ingredient:
          "复方制剂无糖配方每片含碳酸钙750毫克(相当于钙300毫克)辅料为山梨糖醇、硬脂酸镁、阿司帕坦、柠檬酸、天然水果香料和着色剂",
        enterprise: "安士制药(中山)有限公司",
        productName: "迪巧 儿童维D钙咀嚼片",
        commoName: "儿童维D钙咀嚼片",
        dosage: "口服，咀嚼后咽下儿童，1次1片，1日1次或遵医嘱",
        specification: ["60片"],
        precautions:
          "1.心肾功能不全者慎用 2.尿钙或者血钙浓度过高者禁用 3.洋地黄化的病人禁用 4.当药品性状发生改变时禁止使用 5.儿童必须在成人监护下使用 6.请将此药品放在儿童不能接触的地方 7.如果服用过量或出现严重不良反应，应立即就医 8.对本品过敏者禁用，过敏体质者慎用 9.肾结石患者应在医师指导下使用",
      },
    ],
  },

  // {
  //   category: require("../../../assets/drugImgs/img-title-category07.png"),
  //   type: "医疗器械",
  //   data: [
  //     {
  //       img: require("../../../assets/drugImgs/elqx-xty.jpg"),
  //       label: "强生 血糖仪 OneTouch",
  //       price: "¥598.00",
  //       sales: 97,
  //       brand: "强生",
  //       manufacturer: " 强生(中国)医疗器材有限公司",
  //       approvalNumber: "沪械注准20172400479 ",
  //       adverseReactions: "尚不明确",
  //       treatment:
  //         "产品与OneTouch Ultra稳豪型血糖试纸配套使用，用于体外测量人体新鲜毛细血管全血中血糖浓度，可用于医院内进行血糖测试和糖尿病患者的自我血糖监测。产品不用于糖尿病的最终诊断，不适用于新生儿血糖监测。",
  //       enterprise: " 强生(中国)医疗器材有限公司",
  //       productName: " 血糖仪",
  //       commoName: " 血糖仪",
  //       dosage: `1.插入试纸。
  //         2.用采血笔获得圆形血滴1微升，开机2分钟内完成。
  //         3.将试纸触碰血液至填满，5秒后血糖仪自动显示结果。`,
  //       specification: ["OneTouch UltraEasy"],
  //       precautions:
  //         "稳豪倍易型血糖仪使用原强生稳豪型血糖仪试纸。请使用者在选购时注意。大多数人做禁食时的血糖测试– 即他们在餐前或是餐后2小时测试血糖。对于禁食的血糖测试，您可以选择在手指或是手臂上测试。",
  //     },

  //     {
  //       img: require("../../../assets/drugImgs/elqx-whq.jpg"),
  //       label: "鱼跃压缩空气式雾化器403M",
  //       price: "¥299.00",
  //       sales: 97,
  //       brand: "鱼跃",
  //       manufacturer: "  江苏鱼跃医疗设备股份有限公司",
  //       approvalNumber: "苏械注准20162260236 ",
  //       adverseReactions: "尚不明确",
  //       treatment: "供药物雾化吸入治疗用",
  //       enterprise: "  江苏鱼跃医疗设备股份有限公司",
  //       productName: " 鱼跃压缩空气式雾化器403M",
  //       commoName: " 鱼跃压缩空气式雾化器403M",
  //       dosage: `1、旋开雾化杯盖，将适量药物倒入雾化杯内，不要超过最大刻度线，旋上雾化杯盖，连接T型接头、波纹管、咬嘴。
  //       2、连接主机电源，打开电源开头，待主机正常工作1-2分钟后，连接主机与喷雾口雾化杯间连接管，进行雾化治疗。
  //       3、治疗时，将咬嘴含入口腔，微闭嘴唇即可。`,
  //       specification: ["403M 1台"],
  //       ingredient: "由压缩泵、机壳、雾化装置等三部分组成。",
  //     },

  //     {
  //       img: require("../../../assets/drugImgs/elqx-xyj.jpg"),
  //       label: "欧姆龙 电子血压计（上臂式）HEM-7136/台",
  //       price: "¥350.00",
  //       sales: 97,
  //       brand: "欧姆龙",
  //       manufacturer: " 欧姆龙健康医疗株式会社",
  //       approvalNumber: "国械注进20162201120 ",
  //       adverseReactions: "尚不明确",
  //       treatment: "用于测量人体血压及脉搏。",
  //       enterprise: " 欧姆龙健康医疗株式会社",
  //       productName: " 电子血压计",
  //       commoName: " 电子血压计",
  //       dosage: `1.坐正放轻松。
  //       2.将臂带置用左手上臂(肘关节之上1-2CM)紧贴肌肤缠上，空气管居中(中指延长线)。
  //       3.袖带卷后，袖带与手臂之间应能自由伸进一个手指头，臂带中间必须与心脏在同一水平线上。
  //       4.可将收放在桌上，手心向上，自然放松。`,
  //       ingredient: "产品由本体、袖带、电源适配器和空气管组成。",
  //       specification: ["1g*6袋"],
  //       precautions: `1.测量血压前30分钟内避免进食、吸烟及剧烈运动。2.正确的测量姿势是坐姿,并且袖带与心脏应处于同一水平面上。所以在柜台前站立测量的血压值可能与正确测量的血压值有些偏差。
  //         3.有些患者坐着测量不方便时,可采用仰卧姿势测量,单手臂要自然伸直,袖带同心脏保持在同一水平面上。
  //         4.测量时不要说话,不要移动手臂或身体。
  //         5.应在安静状态下进行测量,每次测量应保持同种姿势。
  //         6.因为人的左右臂血压值有差异,差异可高达40mmHg,所以应该经常保持在同一手臂测量,这样才有比较性。
  //         7.同一人连续测量时不要超过3次,连续测量时应松开袖带使手臂休息至少1分钟,再进行测量。一般来说,应以三次测量的平均值为准。
  //         8.本机袖带尺寸适用于12岁以上成人臂围。新生儿不适宜用本产品。`,
  //     },

  //     {
  //       img: require("../../../assets/drugImgs/elqx-tzq.jpg"),
  //       label: "鱼跃单用听诊器 插入式单用（A型）",
  //       price: "¥26.00",
  //       sales: 97,
  //       brand: "鱼跃",
  //       manufacturer: "  江苏鱼跃医疗设备股份有限公司",
  //       approvalNumber:
  //         "苏镇械备20170016（原苏镇食药监械(准)字2011第1200018号） ",
  //       adverseReactions: "尚不明确",
  //       treatment:
  //         "听诊器产品供听诊人体的心、肺、脉等器官的声响变化，作诊断用的诊察仪器。",
  //       enterprise: "  江苏鱼跃医疗设备股份有限公司",
  //       productName: " 听诊器",
  //       commoName: " 听诊器",
  //       dosage: `1.首先，将双耳听筒置入耳内。
  //       2.轻轻用手拍动膜片，可以听到声音，这样可确定听诊器正处于待用状态
  //       3.如果听不到手拍膜片的振动声，将听头转到180°，听到咔嗒声，表示入位，朝向相反一侧
  //       4.然后，再用手轻拍膜片，此时应该听到振动声了，这表示听诊器已设定好待用
  //       5.这时你可以用该听诊器诊断被查患者了。
  //       6.换用胸部听头时的操作方法同上
  //       6.换用胸部听头时的操作方法同上 1.首先，将双耳听筒置入耳内。
  //       2.轻轻用手拍动膜片，可以听到声音，这样可确定听诊器正处于待用状态
  //       `,
  //       ingredient:
  //         "耳塞、耳环、 弹萎片、短胶管、三通、铭牌、 扁形听珍头、说明(合保修卡)、合格证、膜片",
  //       specification: ["插入式单用（A型）"],
  //       precautions: ``,
  //     },
  //   ],
  // },
];
